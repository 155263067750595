<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_one-click-map-add-type-help-online'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_one-click-map-add'
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div
          v-if="
            levelResources && levelResources.data && levelResources.data.length
          "
          class="component-title"
        >
          {{ levelResources.data[0].full_path[0] }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
          <router-link
            v-if="mapTypes.length"
            :to="{
              name: 'r_one-click-map-add-types-mode'
            }"
          >
            <icon class="rotate-1" icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper level-search" v-if="search">
        <div class="search-field">
          <form @submit.prevent="fetchResources" novalidate>
            <input
              type="text"
              ref="searchForm"
              @keyup="setSearchQuery($event.target.value)"
              :placeholder="displayLabelName('one-click', 'map-add', 'search')"
            />
            <div class="icon search">
              <icon icon="#cx-app1-search-16x16" width="16" height="16" />
            </div>
            <button class="clear-field" @click="clearSearch" type="button">
              <div class="svg-icon">
                <svg>
                  <use xlink:href="#cx-app1-cross-round"></use>
                </svg>
              </div>
            </button>
          </form>
        </div>
      </section>
    </template>
    <ul
      v-if="
        levelResources &&
          levelResources.data &&
          levelResources.data.filter(
            (tag, index, array) =>
              array.findIndex(
                t => t.resource_type_id == tag.resource_type_id
              ) == index
          ).length
      "
      class="clebex-item-section pill"
    >
      <li
        class="clebex-item-section-item"
        v-for="levelResource in levelResources.data.filter(
          (tag, index, array) =>
            array.findIndex(t => t.resource_type_id == tag.resource_type_id) ==
            index
        )"
        :key="levelResource.id"
      >
        <button
          @click="selectType(levelResource.resource_type_id)"
          class="clebex-pill-link"
        >
          <button class="clebex-item-content-wrapper">
            <span class="label">
              <span class="text">
                <span class="highlight">{{
                  levelResource.resource_type.translations.find(
                    el => el.locale === locale
                  )
                    ? levelResource.resource_type.translations.find(
                        el => el.locale === locale
                      ).attributes.name
                    : levelResource.resource_type.name
                }}</span>
                <span
                  v-if="
                    levelResource.full_path && levelResource.full_path.length
                  "
                  >, {{ levelResource.full_path.join(", ") }}</span
                >
              </span>
            </span>
            <span
              v-if="
                mapTypes.find(el => el.id === levelResource.resource_type_id)
              "
              class="follow-up-icons"
            >
              <span class="follow-up-icon-item">
                <div class="svg-icon">
                  <svg
                    width="12"
                    height="12"
                    style="width: 12px; height: 12px;"
                  >
                    <use xlink:href="#cx-app1-checkmark"></use>
                  </svg>
                </div>
              </span>
            </span>
          </button>
        </button>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import { getLang } from "@/services/http-service";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "OneClickMapAddResources",
  mixins: [helpOnlineMixin],
  data() {
    return {
      processing: false,
      show: false,
      search: null,
      helpSlug: "one-click-settings-map-add-type"
    };
  },
  created() {
    this.$store.commit("oneClick/setMapTypes", [], {
      root: true
    });
    this.fetchResources();
    this.getResourceTypes();
  },
  watch: {
    routeParam(value) {
      if (value) {
        this.fetchResources();
      }
    }
  },
  computed: {
    ...mapState("level", ["levelResources"]),
    ...mapState("resource", ["resourceTypes"]),
    ...mapState("oneClick", ["mapTypes"]),
    routeParam() {
      const { levelId } = this.$route.params;
      return levelId;
    },
    locale() {
      return getLang();
    }
  },
  methods: {
    ...mapActions("level", ["getLevelResources"]),
    ...mapActions("resource", ["getResourceTypes"]),
    toggleSearch() {
      this.search = !this.search;
    },
    fetchResources() {
      this.getLevelResources({
        levelId: this.routeParam,
        params: {
          includes: ["all_parents", "full_path", "resource_type"],
          sort: "FAVORITES"
        }
      });
    },
    displayParentNames(parents) {
      const filteredParents = parents.filter(item => item.name);
      return filteredParents.map(parent => parent.name).join(", ");
    },
    setSearchQuery(inputValue) {
      this.$store.commit("search/setSearchQuery", inputValue, {
        root: true
      });
    },
    clearSearch() {
      this.$refs.searchForm.value = "";
      this.$store.commit("search/setSearchQuery", "", {
        root: true
      });
      this.fetchResources();
    },
    selectType(id) {
      const type = this.mapTypes.find(el => el.id === id);
      let types = [...this.mapTypes];
      if (type) {
        types = types.filter(el => el.id !== id);
      } else {
        types.push(this.resourceTypes.find(el => el.id === id));
      }
      this.$store.commit("oneClick/setMapTypes", types, {
        root: true
      });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  beforeUnmount() {
    this.$store.commit("level/setLevelResources", null, { root: true });
    this.$store.commit("search/setSearchQuery", "", {
      root: true
    });
  }
};
</script>
